import { TOAST_TYPE } from '@base'
import { strings } from '@constants'
import { showToast } from '@data/state/action'
import React from 'react'
import { useDispatch } from 'react-redux'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File

const defaultFonts = ['Arial', 'Comic Sans MS', 'Courier New', 'Impact', 'Georgia', 'Tahoma', 'Trebuchet MS', 'Verdana']

const TextEditor = ({ placeholder, value, onChange, height }) => {
	const dispatch = useDispatch()

	const sortedFontOptions = [
		'Logical',
		'Salesforce Sans',
		'Garamond',
		'Sans-Serif',
		'Serif',
		'Times New Roman',
		'Helvetica',
		...defaultFonts,
	].sort()

	const uploadImageBeforeHandler = (files, info, uploadHandler) => {
		const file = files[0]

		const maxFileSize = 2 * 1024 * 1024
		if (file.size > maxFileSize) {
			dispatch(showToast(true, strings('msg_file_exceeding_2_mb_content'), { type: TOAST_TYPE.ERROR }))
			uploadHandler()
			return
		}

		const reader = new FileReader()
		reader.onload = () => {
			uploadHandler({
				result: [
					{
						url: reader.result,
					},
				],
			})
		}
		reader.onerror = (error) => {
			dispatch(showToast(true, strings('msg_file_not_uploaded'), { type: TOAST_TYPE.ERROR }))
			console.log(error)
			uploadHandler()
		}

		reader.readAsDataURL(file)
	}

	return (
		<SunEditor
			setContents={value || ''}
			onChange={onChange}
			placeholder={placeholder}
			height={height || '100vh'}
			setOptions={{
				buttonList: [
					['undo', 'redo'],
					['font', 'fontSize'],
					// ['paragraphStyle', 'blockquote'],
					['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
					['fontColor', 'hiliteColor'],
					['align', 'list', 'lineHeight'],
					['outdent', 'indent'],

					['table', 'horizontalRule', 'link', 'image'],
					// ['math'] //You must add the 'katex' library at options to use the 'math' plugin.
					// ['imageGallery'], // You must add the "imageGalleryUrl".
					['fullScreen', 'showBlocks', 'codeView'],
					// ['preview', 'print'],
					// ['removeFormat'],

					// ['save', 'template'],
					// '/', Line break
				], // Or Array of button list, eg. [['font', 'align'], ['image']]
				defaultTag: 'div',
				showPathLabel: false,
				font: sortedFontOptions,
			}}
			onImageUploadBefore={uploadImageBeforeHandler}
		/>
	)
}
export default TextEditor
