import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import {
	COLLECTION_STATUS,
	ORG_ROLES,
	REGION_TYPES,
	enquiryPlatform,
	auctionStatus,
	enquiryStatus,
	pastRecordTypes,
	searchFilter,
	transactionSourceStatus,
	transactionTypeStatus,
	zohoStatusFilter,
	LEADS_STATUS,
	LEADS_SOURCE,
	LEADS_INDUSTRY,
	LEAD_TYPE,
	MB_ROLES,
} from '@constants'
import { toggleDrawer } from '@data/state/action/root'
import { Spacings } from '@styles'
import { useEnter } from '@hooks'
import { Button, Input, Select, Calendar, MultiSelect } from '@base'
import { DrawerBodyWrapper, DrawerFooterWrapper } from '../../styled'
import { roles } from '@constants'
import { commodityLocation, commodityProductName, commoditySource } from '@pages/Admin/CommodityPrices'

const InputWrapper = styled.div`
	padding: ${Spacings.SPACING_1B} 0;

	& > div {
		width: 100%;
	}
	& > div > input {
		border-color: ${({ borderDefault, theme }) => borderDefault && theme.palette.background.blue};
	}
`

const SectionItemWrapper = styled.div`
	flex-shrink: unset;
	flex-basis: 100%;
	& > div {
		width: 100%;
	}
`

const SectionItemWrapperWithTwoItems = styled(SectionItemWrapper)`
	display: flex;
	align-items: center;
	& > div {
		flex-grow: 1;
		flex-basis: 50%;
		&:nth-child(2) {
			margin-left: 12px;
		}
	}
`

const enquirySearch = {
	enquiry_id: {
		id: 'enquiry_id',
		label: 'Enquiry Id',
		placeholder: 'Enter enquiry Id',
		value: '',
		show: true,
	},
	enquiry_date: {
		id: 'enquiry_date',
		label: 'From',
		value: '',
		show: true,
	},
	expiry_date: {
		id: 'expiry_date',
		label: 'To',
		value: '',
		show: true,
	},
	product_name: {
		id: 'product_name',
		label: 'Product name',
		placeholder: 'Enter product name',
		value: '',
		show: true,
	},
	location: {
		id: 'location',
		label: 'Delivery location',
		placeholder: 'Enter delivery location or use ',
		value: '',
		show: true,
	},
	organisation: {
		id: 'organisation',
		label: 'Buyer / Supplier',
		placeholder: 'Enter Name / Mobile / Id',
		value: '',
		show: true,
	},
	relationship_manager: {
		id: 'relationship_manager',
		label: 'Relationship manager',
		placeholder: 'Enter Name / Mobile / Id',
		value: '',
		show: true,
	},
	enquiry_status: {
		id: 'enquiry_status',
		label: 'Enquiry status',
		placeholder: 'Enter enquiry status',
		value: '',
		show: true,
	},
}

const enquiryAssignOrdersSearch = {
	enquiry_id: {
		id: 'enquiry_id',
		label: 'Enquiry Id',
		placeholder: 'Enter enquiry Id',
		value: '',
		show: true,
	},
	enquiry_date: {
		id: 'enquiry_date',
		label: 'Enquiry date',
		value: '',
		show: true,
	},
	product_name: {
		id: 'product_name',
		label: 'Product name',
		placeholder: 'Enter product name',
		value: '',
		show: true,
	},
	location: {
		id: 'location',
		label: 'Delivery location',
		placeholder: 'Enter delivery location',
		value: '',
		show: true,
	},
	relationship_manager: {
		id: 'relationship_manager',
		label: 'Relationship manager',
		placeholder: 'Enter relationship manager',
		value: '',
		show: true,
	},
}

const enquiryOrdersSearch = (searchType) => {
	return {
		order_id: {
			id: 'order_id',
			label: 'Order Id',
			placeholder: 'Enter order Id',
			value: '',
			show: true,
		},
		enquiry_id: {
			id: 'enquiry_id',
			label: 'Enquiry Id',
			placeholder: 'Enter enquiry Id',
			value: '',
			show: true,
		},
		from: {
			id: 'from',
			label: 'From',
			value: '',
			show: true,
		},
		to: {
			id: 'to',
			label: 'To',
			value: '',
			show: true,
		},
		product_name: {
			id: 'product_name',
			label: 'Product name',
			placeholder: 'Enter product name',
			value: '',
			show: true,
		},
		location: {
			id: 'location',
			label: 'Delivery location',
			placeholder: 'Enter delivery location or use ',
			value: '',
			show: true,
		},
		organisation: {
			id: 'organisation',
			label: 'Buyer / Supplier',
			placeholder: 'Enter Name / Mobile / Id',
			value: '',
			show: true,
		},
		relationship_manager: {
			id: 'relationship_manager',
			label: 'Relationship manager',
			placeholder: 'Enter Name / Mobile / Id',
			value: '',
			show: true,
		},
		po_number: {
			id: 'po_number',
			label: 'Buyer / Supplier',
			placeholder: 'Search by PO / Invoice number',
			value: '',
			show: true,
		},
	}
}

const activeVoiceSearch = (searchType) => {
	return {
		order_id: {
			id: 'order_id',
			label: 'Order Id',
			placeholder: 'Enter order Id',
			value: '',
			show: true,
		},
		buyer_name: {
			id: 'buyer_name',
			label: 'Buyer name/Phone',
			placeholder: 'Enter buyer name',
			value: '',
			show: true,
		},

		invoice_number: {
			id: 'invoice_number',
			label: 'Invoice number',
			placeholder: 'Enter invoice number',
			value: '',
			show: true,
		},
		vehicle_number: {
			id: 'vehicle_number',
			label: 'Vehicle number',
			placeholder: 'Enter vehicle number',
			value: '',
			show: true,
		},
		driver_number: {
			id: 'driver_number',
			label: 'Driver number',
			placeholder: 'Enter Driver number',
			value: '',
			show: true,
		},
	}
}

const auctionOrdersSearch = (searchType) => {
	return {
		order_id: {
			id: 'order_id',
			label: 'Order Id',
			placeholder: 'Enter order Id',
			value: '',
			show: true,
		},
		listing_id: {
			id: 'listing_id',
			label: 'Listing Id',
			placeholder: 'Enter listing Id',
			value: '',
			show: true,
		},
		from: {
			id: 'from',
			label: 'From',
			value: '',
			show: true,
		},
		to: {
			id: 'to',
			label: 'To',
			value: '',
			show: true,
		},
		product_name: {
			id: 'product_name',
			label: 'Product name',
			placeholder: 'Enter product name',
			value: '',
			show: true,
		},
		buyer_name: {
			id: 'buyer_name',
			label: 'Buyer name',
			placeholder: 'Enter buyer name',
			value: '',
			show: true,
		},
		relationship_manager: {
			id: 'relationship_manager',
			label: 'Relationship manager',
			placeholder: 'Enter relationship manager',
			value: '',
			show: true,
		},
	}
}

const assignAuctionSerach = (searchType) => {
	return {
		auction_id: {
			id: 'auction_id',
			label: 'Auction Id',
			placeholder: 'Enter auction Id',
			value: '',
			show: true,
		},
		auction_opening_time: {
			id: 'auction_opening_time',
			label: 'Auction Opening date',
			value: '',
			show: true,
		},
		product_name: {
			id: 'product_name',
			label: 'Product name',
			placeholder: 'Enter product name',
			value: '',
			show: true,
		},
		dispatch_address: {
			id: 'dispatch_address',
			label: 'Dispatch location',
			placeholder: 'Enter dispatch location',
			value: '',
			show: true,
		},
		supplier_name: {
			id: 'supplier_name',
			label: 'Supplier name',
			placeholder: 'Enter supplier name',
			value: '',
			show: searchType === searchFilter.ASSIGN_AUCTION_SEARCH,
		},
		relationship_manager: {
			id: 'relationship_manager',
			label: 'Relationship manager',
			placeholder: 'Enter relationship manager',
			value: '',
			show: true,
		},
	}
}

const auctionSerach = {
	auction_id: {
		id: 'auction_id',
		label: 'Auction Id',
		placeholder: 'Enter auction Id',
		value: '',
		show: true,
	},
	auction_opening_time: {
		id: 'auction_opening_time',
		label: 'From',
		value: '',
		show: true,
	},
	auction_closing_time: {
		id: 'auction_closing_time',
		label: 'To',
		value: '',
		show: true,
	},
	product_name: {
		id: 'product_name',
		label: 'Product name',
		placeholder: 'Enter product name',
		value: '',
		show: true,
	},
	dispatch_address: {
		id: 'dispatch_address',
		label: 'Dispatch location',
		placeholder: 'Enter dispatch location',
		value: '',
		show: true,
	},
	supplier_name: {
		id: 'supplier_name',
		label: 'Supplier name',
		placeholder: 'Enter supplier name',
		value: '',
		show: true,
	},
	relationship_manager: {
		id: 'relationship_manager',
		label: 'Relationship manager',
		placeholder: 'Enter relationship manager',
		value: '',
		show: true,
	},
	auction_status: {
		id: 'auction_status',
		label: 'Auction status',
		placeholder: 'Enter auction status',
		value: '',
		show: true,
	},
}

const productSearchFilters = {
	product_name: {
		id: 'product_name',
		label: 'Product name',
		placeholder: 'Enter product name',
		value: '',
		show: true,
	},
}

const commodityPriceFilter = {
	commodity_product: {
		id: 'product_name',
		label: 'Product name',
		placeholder: 'Enter product name',
		value: '',
		show: true,
	},
	commodity_location: {
		id: 'location',
		label: 'Location',
		placeholder: 'Enter Location',
		value: '',
		show: true,
	},
	date: {
		id: 'date',
		label: 'Date',
		value: '',
		show: true,
	},
	commodity_source: {
		id: 'commodity_source',
		label: 'Source',
		placeholder: 'Select Source',
		value: commoditySource.bigbits,
		show: true,
	},
}

const invoiceSearchFilter = {
	full_name: {
		id: 'full_name',
		label: 'Name / Phone',
		placeholder: 'Enter name / phone',
		value: '',
		show: true,
	},
	invoice_number: {
		id: 'invoice_number',
		label: 'Invoice number',
		placeholder: 'Enter invoice number',
		value: '',
		show: true,
	},
}

const underwritingSearchFilter = {
	pan_number: {
		id: 'pan',
		label: 'Pan Number',
		placeholder: 'Enter pan number',
		value: '',
		show: true,
	},
}

const factorySearchFilters = {
	inventory: {
		id: 'inventory',
		label: 'Inventory',
		placeholder: 'Enter inventory',
		value: '',
		show: true,
	},
	factory_name: {
		id: 'factory_name',
		label: 'Factory name',
		placeholder: 'Enter factory name',
		value: '',
		show: true,
	},
	partner_name: {
		id: 'partner_name',
		label: 'Partner name',
		placeholder: 'Enter partner name',
		value: '',
		show: true,
	},
	process_name: {
		id: 'process_name',
		label: 'Process name',
		placeholder: 'Enter Process name',
		value: '',
		show: true,
	},
	location: {
		id: 'location',
		label: 'Location',
		placeholder: 'Enter Location',
		value: '',
		show: true,
	},
}

const factoryManageOrder = {
	partner_name: {
		id: 'partner_name',
		label: 'Partner name',
		placeholder: 'Enter Partner name',
		value: '',
		show: true,
	},
	from: {
		id: 'from',
		label: 'From',
		value: '',
		show: true,
	},
	to: {
		id: 'to',
		label: 'To',
		value: '',
		show: true,
	},
	order_id: {
		id: 'order_id',
		label: 'Order Id',
		placeholder: 'Enter order Id',
		value: '',
		show: true,
	},
	input_order: {
		id: 'input_order',
		label: 'Input order',
		placeholder: 'Enter input order',
		value: '',
		show: true,
	},
	output_order: {
		id: 'output_order',
		label: 'Output order',
		placeholder: 'Enter output order',
		value: '',
		show: true,
	},
	enquiry_id: {
		id: 'enquiry_id',
		label: 'Outgoing order id',
		placeholder: 'Enter outgoing order Id',
		value: '',
		show: true,
	},
}

const usersSearchFilters = {
	user: {
		id: 'user',
		label: 'User',
		placeholder: 'Search by Id / Name / Mobile',
		value: '',
		show: true,
	},

	organisation: {
		id: 'organisation',
		label: 'Organisation',
		placeholder: 'Search by Id / Name',
		value: '',
		show: true,
	},
}

const orgSearchFilters = {
	organisation: {
		id: 'organisation',
		label: 'Organisation',
		placeholder: 'Search by Id / Name',
		value: '',
		show: true,
	},
	user: {
		id: 'user',
		label: 'User',
		placeholder: 'Search by Id / Name / Mobile',
		value: '',
		show: true,
	},
	org_role: {
		id: 'role',
		label: 'Role',
		placeholder: 'Enter role',
		value: '',
		show: true,
	},
	pan: {
		id: 'pan',
		label: 'PAN / GST',
		placeholder: 'Enter number',
		value: '',
		show: true,
	},
	category: {
		id: 'category',
		label: 'Category / Commodity',
		placeholder: 'Search by Id / Name',
		value: '',
		show: true,
	},
	relationship_manager: {
		id: 'relationship-manager',
		label: 'Relationship Manager',
		placeholder: 'Search by relationship manager',
		value: '',
		show: true,
	},
	location: {
		id: 'location',
		label: 'Location',
		placeholder: 'Search by location',
		value: '',
		show: true,
	},
}

const orgLeadFilters = {
	user: {
		id: 'user',
		label: 'User',
		placeholder: 'Search by Id / Name / Mobile',
		value: '',
		show: true,
	},
	manager: {
		id: 'manager',
		label: 'Manager',
		placeholder: 'Search by manager',
		value: '',
		show: true,
	},
	lead_status: {
		id: 'status',
		label: 'Status',
		placeholder: 'Select Status',
		value: '',
		show: true,
	},
	lead_source: {
		id: 'lead-source',
		label: 'Source',
		placeholder: 'Select Lead Source',
		value: '',
		show: true,
	},
	region: {
		id: 'region',
		label: 'Region',
		placeholder: 'Select Region',
		value: '',
		show: true,
	},
	industry: {
		id: 'industry',
		label: 'Industry',
		placeholder: 'Select Industry',
		value: '',
		show: true,
	},
	lead_type: {
		id: 'lead-type',
		label: 'Lead Type',
		placeholder: 'Select Lead Type',
		value: '',
		show: true,
	},
	weight_from: {
		id: 'lead-from-weight',
		label: 'From Required Weight (in MT)',
		placeholder: 'Enter Weight',
		value: '',
		show: true,
	},
	weight_to: {
		id: 'lead-to-weight',
		label: 'To Required Weight (in MT)',
		placeholder: 'Enter Weight',
		value: '',
		show: true,
	},
}

const ledgerFilter = {
	organisation: {
		id: 'organisation',
		label: 'Organisation',
		placeholder: 'Search by Id / Name / Mobile',
		value: '',
		show: true,
	},
	from: {
		id: 'from',
		label: 'From',
		value: '',
		show: true,
	},
	to: {
		id: 'to',
		label: 'To',
		value: '',
		show: true,
	},
	transaction_source: {
		id: 'transaction_source',
		label: 'Transaction Source',
		placeholder: 'Select transaction source',
		value: '',
		show: true,
	},
	transaction_type: {
		id: 'transaction_type',
		label: 'Transaction Type',
		placeholder: 'Select transaction type',
		value: '',
		show: true,
	},
	transaction_amount: {
		id: 'transaction_amount',
		label: 'Transaction Amount',
		value: '',
		show: true,
	},
	invoice_number: {
		id: 'invoice_number',
		label: 'Invoice number',
		placeholder: 'Enter invoice number',
		value: '',
		show: true,
	},
	zoho_status: {
		id: 'zoho_status',
		label: 'Zoho Status',
		placeholder: 'Select Zoho Status',
		value: '',
		show: true,
	},
}

const orgLedgerFilter = {
	from: {
		id: 'from',
		label: 'From',
		value: '',
		show: true,
	},
	to: {
		id: 'to',
		label: 'To',
		value: '',
		show: true,
	},
	transaction_source: {
		id: 'transaction_source',
		label: 'Transaction Source',
		placeholder: 'Select transaction source',
		value: '',
		show: true,
	},
	transaction_type: {
		id: 'transaction_type',
		label: 'Transaction Type',
		placeholder: 'Select transaction type',
		value: '',
		show: true,
	},
	transaction_amount: {
		id: 'transaction_amount',
		label: 'Transaction Amount',
		value: '',
		show: true,
	},
	invoice_number: {
		id: 'invoice_number',
		label: 'Invoice number',
		placeholder: 'Enter invoice number',
		value: '',
		show: true,
	},
}

const salesPurchaseFilter = {
	from: {
		id: 'from',
		label: 'From',
		value: '',
		show: true,
	},
	to: {
		id: 'to',
		label: 'To',
		value: '',
		show: true,
	},
	transaction_source: {
		id: 'transaction_source',
		label: 'Transaction Source',
		placeholder: 'Select transaction source',
		value: '',
		show: true,
	},
	transaction_type: {
		id: 'transaction_type',
		label: 'Transaction Type',
		placeholder: 'Select transaction type',
		value: '',
		show: true,
	},
	invoice_number: {
		id: 'invoice_number',
		label: 'Invoice number',
		placeholder: 'Enter invoice number',
		value: '',
		show: true,
	},
}

const pastReportsSearchFilter = {
	name: {
		id: 'name',
		label: 'Name',
		placeholder: 'Enter name',
		value: '',
		show: true,
	},
	pan_number: {
		id: 'pan',
		label: 'Pan Number',
		placeholder: 'Enter pan number',
		value: '',
		show: true,
	},
	excess_ctc_from: {
		id: 'excess_ctc_from',
		label: 'CTC From',
		placeholder: 'Enter CTC From',
		value: '',
		show: true,
	},
	excess_ctc_to: {
		id: 'excess_ctc_to',
		label: 'CTC To',
		placeholder: 'Enter CTC To',
		value: '',
		show: true,
	},
	gm_from: {
		id: 'gm_from',
		label: 'GM From',
		placeholder: 'Enter GM From',
		value: '',
		show: true,
	},
	gm_to: {
		id: 'gm_to',
		label: 'GM To',
		placeholder: 'Enter GM To',
		value: '',
		show: true,
	},
	gmv_from: {
		id: 'gmv_from',
		label: 'GMV From',
		placeholder: 'Enter GMV From',
		value: '',
		show: true,
	},
	gmv_to: {
		id: 'gmv_to',
		label: 'GMV To',
		placeholder: 'Enter GMV To',
		value: '',
		show: true,
	},
	past_delays_from: {
		id: 'past_delays_from',
		label: 'Past Delays From',
		placeholder: 'Enter Past Delays From',
		value: '',
		show: true,
	},
	past_delays_to: {
		id: 'past_delays_to',
		label: 'Past Delays To',
		placeholder: 'Enter Past Delays To',
		value: '',
		show: true,
	},
	payment_terms_from: {
		id: 'payment_terms_from',
		label: 'Payment Terms From',
		placeholder: 'Enter Payment Terms From',
		value: '',
		show: true,
	},
	payment_terms_to: {
		id: 'payment_terms_to',
		label: 'Payment Terms To',
		placeholder: 'Enter Payment Terms To',
		value: '',
		show: true,
	},
	past_record_type: {
		id: 'record_type',
		label: 'Record Type',
		placeholder: 'Select Record Type',
		value: pastRecordTypes.OVER_ALL,
		show: true,
	},
}

const bannerSearchFilter = {
	banner_type: {
		id: 'banner_type',
		label: 'Type',
		placeholder: 'Enter type',
		value: '',
		show: true,
	},
	banner_role: {
		id: 'banner_role',
		label: 'Role',
		placeholder: 'Enter role',
		value: '',
		show: true,
	},
	banner_platform: {
		id: 'banner_platform',
		label: 'Platform',
		placeholder: 'Enter platform',
		value: '',
		show: true,
	},
}

const collectionSearchFilter = {
	user: {
		id: 'user',
		label: 'User',
		placeholder: 'Search by Id / Name / Mobile',
		value: '',
		show: true,
	},
	manager: {
		id: 'manager',
		label: 'Manager',
		placeholder: 'Search by Manager Name',
		value: '',
		show: true,
	},
	status: {
		id: 'status',
		label: 'Collection status',
		placeholder: 'Enter collection status',
		value: '',
		show: true,
	},
	region: {
		id: 'region',
		label: 'Region',
		placeholder: 'Select Region',
		value: '',
		show: true,
	},
	platform: {
		id: 'platform',
		label: 'Platform',
		placeholder: 'Select Platform',
		value: '',
		show: true,
	},
}

const salescollectionSearchFilter = {
	user: {
		id: 'user',
		label: 'User',
		placeholder: 'Search by Id / Name / Mobile',
		value: '',
		show: true,
	},
	status: {
		id: 'status',
		label: 'Collection status',
		placeholder: 'Enter collection status',
		value: '',
		show: true,
	},
	region: {
		id: 'region',
		label: 'Region',
		placeholder: 'Select Region',
		value: '',
		show: true,
	},
	platform: {
		id: 'platform',
		label: 'Platform',
		placeholder: 'Select Platform',
		value: '',
		show: true,
	},
}

const blogSearchFilter = {
	from: {
		id: 'from',
		label: 'From',
		value: '',
		show: true,
	},
	to: {
		id: 'to',
		label: 'To',
		value: '',
		show: true,
	},
	heading: {
		id: 'heading',
		label: 'Blog Heading',
		placeholder: 'Type here...',
		value: '',
		show: true,
	},
	slug: {
		id: 'slug',
		label: 'Slug',
		placeholder: 'Type here...',
		value: '',
		show: true,
	},
	source: {
		id: 'source',
		label: 'Source',
		placeholder: 'Enter Source',
		value: '',
		show: true,
	},
	summary: {
		id: 'summary',
		label: 'Summary',
		placeholder: 'Type here...',
		value: '',
		show: true,
	},
	title: {
		id: 'title',
		label: 'Title',
		placeholder: 'Type here...',
		value: '',
		show: true,
	},
}

const newsSearchFilter = {
	from: {
		id: 'from',
		label: 'From',
		value: '',
		show: true,
	},
	to: {
		id: 'to',
		label: 'To',
		value: '',
		show: true,
	},
	heading: {
		id: 'heading',
		label: 'News Heading',
		placeholder: 'Type here...',
		value: '',
		show: true,
	},
	slug: {
		id: 'slug',
		label: 'Slug',
		placeholder: 'Type here...',
		value: '',
		show: true,
	},
	source: {
		id: 'source',
		label: 'Source',
		placeholder: 'Enter Source',
		value: '',
		show: true,
	},
	summary: {
		id: 'summary',
		label: 'Summary',
		placeholder: 'Type here...',
		value: '',
		show: true,
	},
	title: {
		id: 'title',
		label: 'Title',
		placeholder: 'Type here...',
		value: '',
		show: true,
	},
}

const adminListFilter = {
	name: {
		id: 'admin-name',
		label: 'Name',
		placeholder: 'Type here...',
		value: '',
		show: true,
	},
	mobile_number: {
		id: 'admin-mobile-number',
		label: 'Mobile Number',
		placeholder: 'Type here...',
		value: '',
		show: true,
	},
	region: {
		id: 'admin-region',
		label: 'Region',
		placeholder: 'Select Region',
		value: '',
		show: true,
		openBelow: true,
	},
	admin_role: {
		id: 'admin-role',
		label: 'Role',
		placeholder: 'Select Role',
		value: '',
		show: true,
	},
}

const getDefaultState = (searchType) => {
	switch (searchType) {
		case searchFilter.ENQUIRY_SEARCH:
		case searchFilter.ASSIGN_ENQUIRY_SEARCH:
			return enquirySearch

		// return enquiryAssignOrdersSearch(searchType)
		case searchFilter.ENQUIRY_ORDER_SEARCH:
			return enquiryOrdersSearch(searchType)
		case searchFilter.ACTIVE_INVOICE_SEARCH:
			return activeVoiceSearch(searchType)
		case searchFilter.AUCTION_ORDER_SEARCH:
			return auctionOrdersSearch(searchType)
		case searchFilter.AUCTION_SEARCH:
			return auctionSerach
		case searchFilter.ASSIGN_AUCTION_SEARCH:
			return assignAuctionSerach(searchType)
		case searchFilter.PRODUCT_SEARCH:
			return productSearchFilters
		case searchFilter.USERS_SEARCH:
			return usersSearchFilters
		case searchFilter.ORG_SEARCH:
			return orgSearchFilters
		case searchFilter.BUYER_ENQUIRY_SEARCH:
			return enquiryAssignOrdersSearch
		case searchFilter.ADMIN_PURCHASE_FILTER:
			return ledgerFilter
		case searchFilter.ADMIN_ORG_LEDGER_FILTER:
			return orgLedgerFilter
		case searchFilter.SALES_PURCHASE_FILTER:
			return salesPurchaseFilter
		case searchFilter.COMMODITY_PRICE_SEARCH:
			return commodityPriceFilter
		case searchFilter.INVOICE_SEARCH:
			return invoiceSearchFilter
		case searchFilter.UNDERWRITING_SEARCH:
			return underwritingSearchFilter
		case searchFilter.PAST_REPORTS:
			return pastReportsSearchFilter
		case searchFilter.ADMIN_BANNER_SEARCH:
			return bannerSearchFilter
		case searchFilter.ORG_LEADS:
			return orgLeadFilters
		//  buyer
		case searchFilter.BUYER_LOGISTICS_SEARCH:
			return enquiryAssignOrdersSearch(searchType)
		case searchFilter.BUYER_AUCTION_SEARCH:
			return assignAuctionSerach(searchType)
		case searchFilter.BUYER_ENQUIRY_ORDER_SEARCH:
			return enquiryOrdersSearch(searchType)
		case searchFilter.CLOUD_FACTORY_SEARCH:
			return factorySearchFilters
		case searchFilter.FACTORY_MANAGE_ORDER_SEARCH:
			return factoryManageOrder
		case searchFilter.BUYER_PURCHASE_FILTER:
			return salesPurchaseFilter
		case searchFilter.COLLECTION_FILTER:
			return collectionSearchFilter
		case searchFilter.SALES_COLLECTION_FILTER:
			return salescollectionSearchFilter
		case searchFilter.BLOG_FILTER:
			return blogSearchFilter
		case searchFilter.NEWS_FILTER:
			return newsSearchFilter
		case searchFilter.ADMIN_LIST_FILTER:
			return adminListFilter
		default:
			break
	}
}

const SearchPanel = ({ searchType }) => {
	const defaultSearchState = useMemo(() => getDefaultState(searchType), [searchType])
	const dispatch = useDispatch()
	const [searchParams, setSearchParams] = useSearchParams()
	const [searchState, setSearchState] = useState(defaultSearchState)

	const enquiryStatuses = useMemo(() => {
		return Object.entries(enquiryStatus).map(([key, value]) => ({ key, label: value }))
	}, [])

	const auctionStatuses = useMemo(() => {
		return Object.entries(auctionStatus).map(([key, value]) => ({ key, label: value }))
	}, [])

	const transactionSourceStatuses = useMemo(() => {
		return Object.entries(transactionSourceStatus).map(([key, value]) => ({ key, label: value }))
	}, [])
	const transactionTypeStatuses = useMemo(() => {
		return Object.entries(transactionTypeStatus).map(([key, value]) => ({ key, label: value }))
	}, [])

	const userRoles = useMemo(() => {
		return Object.entries(roles).map(([key, value]) => ({ key, label: value }))
	}, [])

	const adminRoles = useMemo(() => {
		return Object.entries(MB_ROLES).map(([key, value]) => ({ key, label: value }))
	})
	const organisationRoles = useMemo(() => {
		return Object.values(ORG_ROLES).map(({ platform, role }) => ({ key: `${platform}_${role}`, label: `${platform}_${role}` }))
	}, [])

	const enableSearchButton = useMemo(() => Object.values(searchState).some((_field) => _field.value), [searchState])

	const commodityProductNames = useMemo(() => {
		return Object.entries(commodityProductName).map(([key, value]) => ({ key, label: value }))
	}, [])

	const commodityLocations = useMemo(() => {
		return Object.entries(commodityLocation).map(([key, value]) => ({ key, label: value }))
	}, [])

	const commoditySources = useMemo(() => {
		return Object.entries(commoditySource).map(([key, value]) => ({ key, label: value }))
	}, [])

	const pastRecordTypeSources = useMemo(() => {
		return Object.entries(pastRecordTypes).map(([key, value]) => ({ key, label: value }))
	}, [])

	const collectionStatus = useMemo(() => {
		return Object.entries(COLLECTION_STATUS).map(([key, value]) => ({ key, label: value }))
	}, [])

	const RegionTypes = useMemo(() => {
		return Object.entries(REGION_TYPES).map(([key, value]) => ({ key, label: value }))
	}, [])

	const leadStatusTypes = useMemo(() => {
		return Object.entries(LEADS_STATUS).map(([key, value]) => ({ key, label: value }))
	})

	const leadSourceTypes = useMemo(() => {
		return Object.entries(LEADS_SOURCE).map(([key, value]) => ({ key, label: value }))
	})
	const leadIndustryTypes = useMemo(() => {
		return Object.entries(LEADS_INDUSTRY).map(([key, value]) => ({ key, label: value }))
	})
	const leadType = useMemo(() => {
		return Object.entries(LEAD_TYPE).map(([key, value]) => ({ key, label: value }))
	})
	const platformTypes = useMemo(() => {
		return Object.entries(enquiryPlatform).map(([k, value]) => ({ key: value.name, label: value.name }))
	}, [])

	const zohoStatusTypes = useMemo(() => {
		return Object.entries(zohoStatusFilter).map(([k, value]) => ({ key: value.name, label: value.name }))
	}, [])

	const updateStateFromSearchParams = (queryObject) => {
		if (!queryObject || !queryObject.toString()) return

		setSearchState((_state) => {
			const keys = Object.keys(_state)

			return keys.reduce((_acc, _key) => {
				const value = queryObject.get(_key)
				_acc[_key] = {
					..._state[_key],
					value: value ?? defaultSearchState[_key].value,
				}
				return _acc
			}, {})
		})
	}

	const querySameAsState = useCallback((query, state) => {
		return Object.entries(state).every(([key, val]) => (!val.value && !query.get(key)) || (val.value && val.value === query.get(key)))
	}, [])

	const searchHandler = useCallback(() => {
		if (!enableSearchButton) return

		if (querySameAsState(searchParams, searchState)) {
			dispatch(toggleDrawer(false))
			return
		}

		const queryObject = Object.entries(searchState).reduce((acc, [key, val]) => {
			val.value && (acc[key] = val.value)
			return acc
		}, {})

		setSearchParams(queryObject)
		dispatch(toggleDrawer(false))
	}, [enableSearchButton, searchState, searchParams, setSearchParams])

	useEnter(searchHandler)

	useEffect(() => {
		updateStateFromSearchParams(searchParams)
	}, [searchParams])

	const updateState = (key) => (value) => {
		setSearchState((_state) => ({
			..._state,
			[key]: {
				..._state[key],
				value,
			},
		}))
	}

	const updateEnquiryState = (statusObject) => {
		updateState('enquiry_status')(statusObject.label)
	}

	const updatecommodityProductName = (statusObject) => {
		updateState('commodity_product')(statusObject.label)
	}

	const updatecommodityLocation = (statusObject) => {
		updateState('commodity_location')(statusObject.label)
	}

	const updateCommoditySource = (statusObject) => {
		updateState('commodity_source')(statusObject.label)
	}

	const updateAuctionState = (statusObject) => {
		updateState('auction_status')(statusObject.label)
	}
	const updateTransactionSourceState = (statusObject) => {
		updateState('transaction_source')(statusObject.label)
	}

	const updateTransactionTypeState = (statusObject) => {
		updateState('transaction_type')(statusObject.label)
	}

	const updateRecordType = (statusObject) => {
		updateState('past_record_type')(statusObject.label)
	}

	const updateCollectionStatusType = (statusObject) => {
		const statusString = statusObject.map((obj) => obj['key']).toString()
		updateState('status')(statusString)
	}
	const updateCollectionRegionType = (statusObject) => {
		updateState('region')(statusObject.label)
	}
	const updateLeadStatusType = (statusObject) => {
		updateState('lead_status')(statusObject.key)
	}
	const updateLeadSourceType = (statusObject) => {
		const statusString = statusObject.map((obj) => obj['key']).toString()
		updateState('lead_source')(statusString)
	}
	const updateLeadIndustryType = (statusObject) => {
		updateState('industry')(statusObject.key)
	}
	const updateLeadType = (statusObject) => {
		updateState('lead_type')(statusObject.key)
	}
	const updateCollectionPlatform = (statusObject) => {
		updateState('platform')(statusObject.label)
	}

	const updateZohoStatus = (statusObject) => {
		updateState('zoho_status')(statusObject.label)
	}

	const clearSearch = () => {
		setSearchState(defaultSearchState)
		setSearchParams({ page: searchParams.get('page') ?? 0 })
	}

	const getDate = (date) => (+date === 0 ? new Date().getTime() : +date)

	return (
		<>
			<DrawerBodyWrapper>
				{searchState.auction_id?.show && (
					<InputWrapper>
						<Input
							label={searchState.auction_id.label}
							id={searchState.auction_id.id}
							type='text'
							value={searchState.auction_id.value}
							onChange={updateState('auction_id')}
							placeholder={searchState.auction_id.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.order_id?.show && (
					<InputWrapper>
						<Input
							label={searchState.order_id.label}
							id={searchState.order_id.id}
							type='text'
							value={searchState.order_id.value}
							onChange={updateState('order_id')}
							placeholder={searchState.order_id.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.enquiry_id?.show && (
					<InputWrapper borderDefault>
						<Input
							label={searchState.enquiry_id.label}
							id={searchState.enquiry_id.id}
							type='text'
							value={searchState.enquiry_id.value}
							onChange={updateState('enquiry_id')}
							placeholder={searchState.enquiry_id.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.listing_id?.show && (
					<InputWrapper>
						<Input
							label={searchState.listing_id.label}
							id={searchState.listing_id.id}
							type='text'
							value={searchState.listing_id.value}
							onChange={updateState('listing_id')}
							placeholder={searchState.listing_id.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.order_date?.show && (
					<InputWrapper>
						<Calendar
							label={searchState.order_date.label}
							id={searchState.order_date.id}
							value={getDate(searchState.order_date.value)}
							onChange={updateState('order_date')}
							isSearch
						/>
					</InputWrapper>
				)}
				{searchState.date?.show && (
					<InputWrapper>
						<Calendar
							label={searchState.date.label}
							id={searchState.date.id}
							value={getDate(searchState.date.value)}
							onChange={updateState('date')}
							endOfDay={false}
							isSearch
						/>
					</InputWrapper>
				)}
				{searchState.organisation?.show && (
					<InputWrapper>
						<Input
							label={searchState.organisation.label}
							id={searchState.organisation.id}
							type='text'
							value={searchState.organisation.value}
							onChange={updateState('organisation')}
							placeholder={searchState.organisation.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.enquiry_status?.show && (
					<InputWrapper>
						<Select
							label={searchState.enquiry_status.label}
							id={searchState.enquiry_status.id}
							placeholder={searchState.enquiry_status.placeholder}
							value={enquiryStatuses.find((_s) => _s.label === searchState.enquiry_status.value) ?? ''}
							onChange={updateEnquiryState}
							data={enquiryStatuses}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState.enquiry_date?.show && (
					<InputWrapper>
						<Calendar
							label={searchState.enquiry_date.label}
							id={searchState.enquiry_date.id}
							value={getDate(searchState.enquiry_date.value)}
							onChange={updateState('enquiry_date')}
							endOfDay={false}
							isSearch
						/>
					</InputWrapper>
				)}
				{searchState.expiry_date?.show && (
					<InputWrapper>
						<Calendar
							label={searchState.expiry_date.label}
							id={searchState.expiry_date.id}
							value={getDate(searchState.expiry_date.value)}
							onChange={updateState('expiry_date')}
							isSearch
						/>
					</InputWrapper>
				)}
				{searchState.from?.show && (
					<InputWrapper>
						<Calendar
							label={searchState.from.label}
							id={searchState.from.id}
							value={getDate(searchState.from.value)}
							onChange={updateState('from')}
							endOfDay={false}
							isSearch
						/>
					</InputWrapper>
				)}
				{searchState.to?.show && (
					<InputWrapper>
						<Calendar
							label={searchState.to.label}
							id={searchState.to.id}
							value={getDate(searchState.to.value)}
							onChange={updateState('to')}
							isSearch
						/>
					</InputWrapper>
				)}
				{searchState.auction_opening_time?.show && (
					<InputWrapper>
						<Calendar
							label={searchState.auction_opening_time.label}
							id={searchState.auction_opening_time.id}
							value={getDate(searchState.auction_opening_time.value)}
							onChange={updateState('auction_opening_time')}
							endOfDay={false}
							isSearch
						/>
					</InputWrapper>
				)}
				{searchState.auction_closing_time?.show && (
					<InputWrapper>
						<Calendar
							label={searchState.auction_closing_time.label}
							id={searchState.auction_closing_time.id}
							value={getDate(searchState.auction_closing_time.value)}
							onChange={updateState('auction_closing_time')}
							isSearch
						/>
					</InputWrapper>
				)}
				{searchState.product_name?.show && (
					<InputWrapper>
						<Input
							label={searchState.product_name.label}
							id={searchState.product_name.id}
							type='text'
							value={searchState.product_name.value}
							onChange={updateState('product_name')}
							placeholder={searchState.product_name.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.dispatch_address?.show && (
					<InputWrapper>
						<Input
							label={searchState.dispatch_address.label}
							id={searchState.dispatch_address.id}
							type='text'
							value={searchState.dispatch_address.value}
							onChange={updateState('dispatch_address')}
							placeholder={searchState.dispatch_address.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.name?.show && (
					<InputWrapper>
						<Input
							label={searchState.name.label}
							id={searchState.name.id}
							type='text'
							value={searchState.name.value}
							onChange={updateState('name')}
							placeholder={searchState.name.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.buyer_name?.show && (
					<InputWrapper>
						<Input
							label={searchState.buyer_name.label}
							id={searchState.buyer_name.id}
							type='text'
							value={searchState.buyer_name.value}
							onChange={updateState('buyer_name')}
							placeholder={searchState.buyer_name.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.supplier_name?.show && (
					<InputWrapper>
						<Input
							label={searchState.supplier_name.label}
							id={searchState.supplier_name.id}
							type='text'
							value={searchState.supplier_name.value}
							onChange={updateState('supplier_name')}
							placeholder={searchState.supplier_name.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.enquiry_by?.show && (
					<InputWrapper>
						<Input
							label={searchState.enquiry_by.label}
							id={searchState.enquiry_by.id}
							type='text'
							value={searchState.enquiry_by.value}
							onChange={updateState('enquiry_by')}
							placeholder={searchState.enquiry_by.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.commodity_product?.show && (
					<InputWrapper>
						<Select
							label={searchState.commodity_product.label}
							id={searchState.commodity_product.id}
							placeholder={searchState.commodity_product.placeholder}
							value={commodityProductNames.find((_s) => _s.label === searchState.commodity_product.value) ?? ''}
							onChange={updatecommodityProductName}
							data={commodityProductNames}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState.commodity_location?.show && (
					<InputWrapper>
						<Select
							label={searchState.commodity_location.label}
							id={searchState.commodity_location.id}
							placeholder={searchState.commodity_location.placeholder}
							value={commodityLocations.find((_s) => _s.label === searchState.commodity_location.value) ?? ''}
							onChange={updatecommodityLocation}
							data={commodityLocations}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState.commodity_source?.show && (
					<InputWrapper>
						<Select
							label={searchState.commodity_source.label}
							id={searchState.commodity_source.id}
							placeholder={searchState.commodity_source.placeholder}
							value={commoditySources.find((_s) => _s.label === searchState.commodity_source.value) ?? ''}
							onChange={updateCommoditySource}
							data={commoditySources}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}

				{searchState.auction_status?.show && (
					<InputWrapper>
						<Select
							label={searchState.auction_status.label}
							id={searchState.auction_status.id}
							placeholder={searchState.auction_status.placeholder}
							value={auctionStatuses.find((_s) => _s.label === searchState.auction_status.value) ?? ''}
							onChange={updateAuctionState}
							data={auctionStatuses}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState.user_id?.show && (
					<InputWrapper>
						<Input
							label={searchState.user_id.label}
							id={searchState.user_id.id}
							type='text'
							value={searchState.user_id.value}
							onChange={updateState('user_id')}
							placeholder={searchState.user_id.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.full_name?.show && (
					<InputWrapper>
						<Input
							label={searchState.full_name.label}
							id={searchState.full_name.id}
							type='text'
							value={searchState.full_name.value}
							onChange={updateState('full_name')}
							placeholder={searchState.full_name.placeholder}
						/>
					</InputWrapper>
				)}

				{searchState.location?.show && (
					<InputWrapper>
						<Input
							label={searchState.location.label}
							id={searchState.location.id}
							type='text'
							value={searchState.location.value}
							onChange={updateState('location')}
							placeholder={searchState.location.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.organisation_id?.show && (
					<InputWrapper>
						<Input
							label={searchState.organisation_id.label}
							id={searchState.organisation_id.id}
							type='text'
							value={searchState.organisation_id.value}
							onChange={updateState('organisation_id')}
							placeholder={searchState.organisation_id.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.organisation_name?.show && (
					<InputWrapper>
						<Input
							label={searchState.organisation_name.label}
							id={searchState.organisation_name.id}
							type='text'
							value={searchState.organisation_name.value}
							onChange={updateState('organisation_name')}
							placeholder={searchState.organisation_name.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.mobile_number?.show && (
					<InputWrapper>
						<Input
							label={searchState.mobile_number.label}
							id={searchState.mobile_number.id}
							type='text'
							value={searchState.mobile_number.value}
							onChange={updateState('mobile_number')}
							placeholder={searchState.mobile_number.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.role?.show && (
					<InputWrapper>
						<Select
							label={searchState.role.label}
							id={searchState.role.id}
							data={userRoles}
							type='text'
							value={userRoles.find((_s) => _s.label === searchState.role.value) ?? ''}
							onChange={(value) => updateState('role')(value.label)}
							placeholder={searchState.role.placeholder}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState?.admin_role?.show && (
					<InputWrapper>
						<Select
							label={searchState.admin_role.label}
							id={searchState.admin_role.id}
							data={adminRoles}
							type='text'
							value={adminRoles.find((_s) => _s.label === searchState.admin_role.value) ?? ''}
							onChange={(value) => updateState('admin_role')(value.label)}
							placeholder={searchState.admin_role.placeholder}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState.user?.show && (
					<InputWrapper>
						<Input
							label={searchState.user.label}
							id={searchState.user.id}
							type='text'
							value={searchState.user.value}
							onChange={updateState('user')}
							placeholder={searchState.user.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.org_role?.show && (
					<InputWrapper>
						<Select
							label={searchState.org_role.label}
							id={searchState.org_role.id}
							data={organisationRoles}
							type='text'
							value={organisationRoles.find((_s) => _s.label === searchState.org_role.value) ?? ''}
							onChange={(value) => updateState('org_role')(value.label)}
							placeholder={searchState.org_role.placeholder}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState.company_name?.show && (
					<InputWrapper>
						<Input
							label={searchState.company_name.label}
							id={searchState.company_name.id}
							type='text'
							value={searchState.company_name.value}
							onChange={updateState('company_name')}
							placeholder={searchState.company_name.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.factory_name?.show && (
					<InputWrapper>
						<Input
							label={searchState.factory_name.label}
							id={searchState.factory_name.id}
							type='text'
							value={searchState.factory_name.value}
							onChange={updateState('factory_name')}
							placeholder={searchState.factory_name.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.partner_name?.show && (
					<InputWrapper>
						<Input
							label={searchState.partner_name.label}
							id={searchState.partner_name.id}
							type='text'
							value={searchState.partner_name.value}
							onChange={updateState('partner_name')}
							placeholder={searchState.partner_name.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.process_name?.show && (
					<InputWrapper>
						<Input
							label={searchState.process_name.label}
							id={searchState.process_name.id}
							type='text'
							value={searchState.process_name.value}
							onChange={updateState('process_name')}
							placeholder={searchState.process_name.placeholder}
						/>
					</InputWrapper>
				)}

				{searchState.transaction_source?.show && (
					<InputWrapper>
						<Select
							label={searchState.transaction_source.label}
							id={searchState.transaction_source.id}
							placeholder={searchState.transaction_source.placeholder}
							value={transactionSourceStatuses.find((_s) => _s.label === searchState.transaction_source.value) ?? ''}
							onChange={updateTransactionSourceState}
							data={transactionSourceStatuses}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState.transaction_type?.show && (
					<InputWrapper>
						<Select
							label={searchState.transaction_type.label}
							id={searchState.transaction_type.id}
							placeholder={searchState.transaction_type.placeholder}
							value={transactionTypeStatuses.find((_s) => _s.label === searchState.transaction_type.value) ?? ''}
							onChange={updateTransactionTypeState}
							data={transactionTypeStatuses}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState.transaction_amount?.show && (
					<InputWrapper>
						<Input
							label={searchState.transaction_amount.label}
							id={searchState.transaction_amount.id}
							type='number'
							value={searchState.transaction_amount.value}
							onChange={updateState('transaction_amount')}
							placeholder={searchState.transaction_amount.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.invoice_number?.show && (
					<InputWrapper>
						<Input
							label={searchState.invoice_number.label}
							id={searchState.invoice_number.id}
							type='text'
							value={searchState.invoice_number.value}
							onChange={updateState('invoice_number')}
							placeholder={searchState.invoice_number.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.pan_number?.show && (
					<InputWrapper>
						<Input
							label={searchState.pan_number.label}
							id={searchState.pan_number.id}
							type='text'
							value={searchState.pan_number.value}
							onChange={updateState('pan_number')}
							placeholder={searchState.pan_number.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.pan?.show && (
					<InputWrapper>
						<Input
							label={searchState.pan.label}
							id={searchState.pan.id}
							type='text'
							value={searchState.pan.value}
							onChange={updateState('pan')}
							placeholder={searchState.pan.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.po_number?.show && (
					<InputWrapper>
						<Input
							label={searchState.po_number.label}
							id={searchState.po_number.id}
							type='text'
							value={searchState.po_number.value}
							onChange={updateState('po_number')}
							placeholder={searchState.po_number.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.inventory?.show && (
					<InputWrapper>
						<Input
							label={searchState.inventory.label}
							id={searchState.inventory.id}
							type='text'
							value={searchState.inventory.value}
							onChange={updateState('inventory')}
							placeholder={searchState.inventory.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.input_order?.show && (
					<InputWrapper>
						<Input
							label={searchState.input_order.label}
							id={searchState.input_order.id}
							type='text'
							value={searchState.input_order.value}
							onChange={updateState('input_order')}
							placeholder={searchState.input_order.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.output_order?.show && (
					<InputWrapper>
						<Input
							label={searchState.output_order.label}
							id={searchState.output_order.id}
							type='text'
							value={searchState.output_order.value}
							onChange={updateState('output_order')}
							placeholder={searchState.output_order.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.category?.show && (
					<InputWrapper>
						<Input
							label={searchState.category.label}
							id={searchState.category.id}
							type='text'
							value={searchState.category.value}
							onChange={updateState('category')}
							placeholder={searchState.category.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.relationship_manager?.show && (
					<InputWrapper>
						<Input
							label={searchState.relationship_manager.label}
							id={searchState.relationship_manager.id}
							type='text'
							value={searchState.relationship_manager.value}
							onChange={updateState('relationship_manager')}
							placeholder={searchState.relationship_manager.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.vehicle_number?.show && (
					<InputWrapper>
						<Input
							label={searchState.vehicle_number.label}
							id={searchState.vehicle_number.id}
							type='text'
							value={searchState.vehicle_number.value}
							onChange={updateState('vehicle_number')}
							placeholder={searchState.vehicle_number.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.driver_number?.show && (
					<InputWrapper>
						<Input
							label={searchState.driver_number.label}
							id={searchState.driver_number.id}
							type='text'
							value={searchState.driver_number.value}
							onChange={updateState('driver_number')}
							placeholder={searchState.driver_number.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.payment_terms_from?.show && (
					<SectionItemWrapperWithTwoItems>
						<Input
							label={searchState.payment_terms_from.label}
							id={searchState.payment_terms_from.id}
							type='text'
							value={searchState.payment_terms_from.value}
							onChange={updateState('payment_terms_from')}
							placeholder={searchState.payment_terms_from.placeholder}
						/>
						<Input
							label={searchState.payment_terms_to.label}
							id={searchState.payment_terms_to.id}
							type='text'
							value={searchState.payment_terms_to.value}
							onChange={updateState('payment_terms_to')}
							placeholder={searchState.payment_terms_to.placeholder}
						/>
					</SectionItemWrapperWithTwoItems>
				)}
				{searchState.past_delays_from?.show && (
					<SectionItemWrapperWithTwoItems>
						<Input
							label={searchState.past_delays_from.label}
							id={searchState.past_delays_from.id}
							type='text'
							value={searchState.past_delays_from.value}
							onChange={updateState('past_delays_from')}
							placeholder={searchState.past_delays_from.placeholder}
						/>
						<Input
							label={searchState.past_delays_to.label}
							id={searchState.past_delays_to.id}
							type='text'
							value={searchState.past_delays_to.value}
							onChange={updateState('past_delays_to')}
							placeholder={searchState.past_delays_to.placeholder}
						/>
					</SectionItemWrapperWithTwoItems>
				)}
				{searchState.excess_ctc_from?.show && (
					<SectionItemWrapperWithTwoItems>
						<Input
							label={searchState.excess_ctc_from.label}
							id={searchState.excess_ctc_from.id}
							type='text'
							value={searchState.excess_ctc_from.value}
							onChange={updateState('excess_ctc_from')}
							placeholder={searchState.excess_ctc_from.placeholder}
						/>
						<Input
							label={searchState.excess_ctc_to.label}
							id={searchState.excess_ctc_to.id}
							type='text'
							value={searchState.excess_ctc_to.value}
							onChange={updateState('excess_ctc_to')}
							placeholder={searchState.excess_ctc_to.placeholder}
						/>
					</SectionItemWrapperWithTwoItems>
				)}
				{searchState.gmv_from?.show && (
					<SectionItemWrapperWithTwoItems>
						<Input
							label={searchState.gmv_from.label}
							id={searchState.gmv_from.id}
							type='text'
							value={searchState.gmv_from.value}
							onChange={updateState('gmv_from')}
							placeholder={searchState.gmv_from.placeholder}
						/>
						<Input
							label={searchState.gmv_to.label}
							id={searchState.gmv_to.id}
							type='text'
							value={searchState.gmv_to.value}
							onChange={updateState('gmv_to')}
							placeholder={searchState.gmv_to.placeholder}
						/>
					</SectionItemWrapperWithTwoItems>
				)}
				{searchState.gm_from?.show && (
					<SectionItemWrapperWithTwoItems>
						<Input
							label={searchState.gm_from.label}
							id={searchState.gm_from.id}
							type='text'
							value={searchState.gm_from.value}
							onChange={updateState('gm_from')}
							placeholder={searchState.gm_from.placeholder}
						/>
						<Input
							label={searchState.gm_to.label}
							id={searchState.gm_to.id}
							type='text'
							value={searchState.gm_to.value}
							onChange={updateState('gm_to')}
							placeholder={searchState.gm_to.placeholder}
						/>
					</SectionItemWrapperWithTwoItems>
				)}
				{searchState.past_record_type?.show && (
					<InputWrapper>
						<Select
							label={searchState.past_record_type.label}
							id={searchState.past_record_type.id}
							placeholder={searchState.past_record_type.placeholder}
							value={pastRecordTypeSources.find((_s) => _s.label === searchState.past_record_type.value) ?? pastRecordTypes.OVER_ALL}
							onChange={updateRecordType}
							data={pastRecordTypeSources}
							displayKey='label'
							primaryKey='key'
							openBelow={false}
						/>
					</InputWrapper>
				)}
				{searchState.banner_type?.show && (
					<InputWrapper>
						<Input
							label={searchState.banner_type.label}
							id={searchState.banner_type.id}
							type='text'
							value={searchState.banner_type.value}
							onChange={updateState('banner_type')}
							placeholder={searchState.banner_type.placeholder}
							uppercase
						/>
					</InputWrapper>
				)}
				{searchState.banner_role?.show && (
					<InputWrapper>
						<Input
							label={searchState.banner_role.label}
							id={searchState.banner_role.id}
							type='text'
							value={searchState.banner_role.value}
							onChange={updateState('banner_role')}
							placeholder={searchState.banner_role.placeholder}
							uppercase
						/>
					</InputWrapper>
				)}
				{searchState.banner_platform?.show && (
					<InputWrapper>
						<Input
							label={searchState.banner_platform.label}
							id={searchState.banner_platform.id}
							type='text'
							value={searchState.banner_platform.value}
							onChange={updateState('banner_platform')}
							placeholder={searchState.banner_platform.placeholder}
							uppercase
						/>
					</InputWrapper>
				)}
				{searchState.manager?.show && (
					<InputWrapper>
						<Input
							label={searchState.manager.label}
							id={searchState.manager.id}
							type='text'
							value={searchState.manager.value}
							onChange={updateState('manager')}
							placeholder={searchState.manager.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.status?.show && (
					<InputWrapper>
						<MultiSelect
							label={searchState.status.label}
							id={searchState.status.id}
							placeholder={searchState.status.placeholder}
							value={
								searchState.status?.value.length
									? searchState.status?.value?.split(',')?.map((_s, index) => ({
											key: _s,
											label: Object.keys(COLLECTION_STATUS).find((_k) => _k === _s),
									  }))
									: []
							}
							onChange={updateCollectionStatusType}
							data={collectionStatus}
							displayKey='label'
							primaryKey='key'
							multiple
						/>
					</InputWrapper>
				)}
				{searchState.lead_status?.show && (
					<InputWrapper>
						<Select
							label={searchState.lead_status.label}
							id={searchState.lead_status.id}
							placeholder={searchState.lead_status.placeholder}
							value={leadStatusTypes.find((_s) => _s.key === searchState.lead_status.value) ?? ''}
							onChange={updateLeadStatusType}
							data={leadStatusTypes}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState.weight_from?.show && (
					<InputWrapper>
						<Input
							label={searchState.weight_from.label}
							id={searchState.weight_from.id}
							type='number'
							value={searchState.weight_from.value}
							onChange={updateState('weight_from')}
							placeholder={searchState.weight_from.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.weight_to?.show && (
					<InputWrapper>
						<Input
							label={searchState.weight_to.label}
							id={searchState.weight_to.id}
							type='number'
							value={searchState.weight_to.value}
							onChange={updateState('weight_to')}
							placeholder={searchState.weight_to.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.lead_source?.show && (
					<InputWrapper>
						<MultiSelect
							label={searchState.lead_source.label}
							id={searchState.lead_source.id}
							placeholder={searchState.lead_source.placeholder}
							value={
								searchState.lead_source?.value.length
									? searchState.lead_source?.value?.split(',')?.map((_s, index) => ({
											key: _s,
											label: Object.keys(LEADS_SOURCE).find((_k) => _k === _s),
									  }))
									: []
							}
							onChange={updateLeadSourceType}
							data={leadSourceTypes}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState.industry?.show && (
					<InputWrapper>
						<Select
							label={searchState.industry.label}
							id={searchState.industry.id}
							placeholder={searchState.industry.placeholder}
							value={leadIndustryTypes.find((_s) => _s.key === searchState.industry.value) ?? ''}
							onChange={updateLeadIndustryType}
							data={leadIndustryTypes}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState.lead_type?.show && (
					<InputWrapper>
						<Select
							label={searchState.lead_type.label}
							id={searchState.lead_type.id}
							placeholder={searchState.lead_type.placeholder}
							value={leadType.find((_s) => _s.key === searchState.lead_type.value) ?? ''}
							onChange={updateLeadType}
							data={leadType}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState.region?.show && (
					<InputWrapper>
						<Select
							label={searchState.region.label}
							id={searchState.region.id}
							placeholder={searchState.region.placeholder}
							value={RegionTypes.find((_s) => _s.label === searchState.region.value) ?? ''}
							onChange={updateCollectionRegionType}
							data={RegionTypes}
							displayKey='label'
							primaryKey='key'
							openBelow={searchState?.region?.openBelow ?? false}
						/>
					</InputWrapper>
				)}
				{searchState.platform?.show && (
					<InputWrapper>
						<Select
							label={searchState.platform.label}
							id={searchState.platform.id}
							placeholder={searchState.platform.placeholder}
							value={platformTypes.find((_s) => _s.label === searchState.platform.value) ?? ''}
							onChange={updateCollectionPlatform}
							data={platformTypes}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}

				{searchState.zoho_status?.show && (
					<InputWrapper>
						<Select
							label={searchState.zoho_status.label}
							id={searchState.zoho_status.id}
							placeholder={searchState.zoho_status.placeholder}
							value={zohoStatusTypes.find((_s) => _s.label === searchState.zoho_status.value) ?? ''}
							onChange={updateZohoStatus}
							data={zohoStatusTypes}
							displayKey='label'
							primaryKey='key'
						/>
					</InputWrapper>
				)}
				{searchState.heading?.show && (
					<InputWrapper>
						<Input
							label={searchState.heading.label}
							id={searchState.heading.id}
							type='text'
							value={searchState.heading.value}
							onChange={updateState('heading')}
							placeholder={searchState.heading.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.slug?.show && (
					<InputWrapper>
						<Input
							label={searchState.slug.label}
							id={searchState.slug.id}
							type='text'
							value={searchState.slug.value}
							onChange={updateState('slug')}
							placeholder={searchState.slug.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.source?.show && (
					<InputWrapper>
						<Input
							label={searchState.source.label}
							id={searchState.source.id}
							type='text'
							value={searchState.source.value}
							onChange={updateState('source')}
							placeholder={searchState.source.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.summary?.show && (
					<InputWrapper>
						<Input
							label={searchState.summary.label}
							id={searchState.summary.id}
							type='text'
							value={searchState.summary.value}
							onChange={updateState('summary')}
							placeholder={searchState.summary.placeholder}
						/>
					</InputWrapper>
				)}
				{searchState.title?.show && (
					<InputWrapper>
						<Input
							label={searchState.title.label}
							id={searchState.title.id}
							type='text'
							value={searchState.title.value}
							onChange={updateState('title')}
							placeholder={searchState.title.placeholder}
						/>
					</InputWrapper>
				)}
			</DrawerBodyWrapper>
			<DrawerFooterWrapper>
				<Button margin={`0 0 0 auto`} small uppercase type='primary' disabled={!enableSearchButton} onClick={searchHandler}>
					Search
				</Button>
				<Button small uppercase type='secondary' variant='destructive' onClick={clearSearch}>
					Clear
				</Button>
			</DrawerFooterWrapper>
		</>
	)
}

export default SearchPanel
